<template>
  <div>
    <v-row class="mt-15">
      <v-col>
        <v-img
          eager
          contain
          height="50"
          src="@/assets/images/logos/logo_roxo.png"
        />
      </v-col>
    </v-row>

    <div class="row pb-5">
      <div class="col">
        <v-img
          eager
          contain
          height="260"
          src="@/assets/images/app/maintenance.png"
        />
      </div>
    </div>

    <div class="row text-center">
      <div class="col-12">
        <h3 style="word-break: keep-all">
          Desculpe-nos, nosso sistema está em manutenção no momento.
        </h3>
      </div>
      <div class="col">
        <h3>
          Voltaremos em breve com novidades. Obrigado pela sua compreensão.
        </h3>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-12">
        <v-btn @click="refresh()" color="primary">
          <v-icon left>mdi-refresh</v-icon>
          Tentar novamente
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    console.log("Eita lasqueira, tamo em manutenção");
  },

  methods: {
    refresh() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style lang='sass'>
#title
  font-family: 'Poppins'
  font-style: normal
</style>